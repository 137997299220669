import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class cDataService {
  // File Manager Variables Start
  oGetFolders = new BehaviorSubject<any>("");
  GetFolders = new BehaviorSubject<any>("");
  oGetDocFolders = new BehaviorSubject<any>("");
  selectedFiles = new BehaviorSubject<any>('');
  refresh = new BehaviorSubject<boolean>(false);
  bFetchDeletedFilesCheck = new BehaviorSubject<boolean>(false);
  sCurrentFolderName = new BehaviorSubject<string>('')
  addingNewSubFolder = new BehaviorSubject<string>('')
  bRenameLoader = new BehaviorSubject<boolean>(false);
  bDisplyDropdown = new BehaviorSubject<boolean>(false);
  bDisplaySettingDropdown = new BehaviorSubject<boolean>(false);
  bShowHeaderSearch = new BehaviorSubject<boolean>(false);
  lAutoCompleteList = new BehaviorSubject<any>([]);
  // File Manager Variables Ends
  bDisplayDocEllipses = new BehaviorSubject<boolean>(false);
  closeSidebar = new BehaviorSubject<any>("");
  showresponse = new BehaviorSubject<any>({ arg1: '', arg2: false });
  accessToken = localStorage.getItem(environment.AccessToken);
  treeMenu = new BehaviorSubject<any>('');
  trash = new BehaviorSubject<boolean>(false);
  selectFolder = new BehaviorSubject<any>({ arg1: '', arg2: false });
  globalSearch = new BehaviorSubject<any>({ folderId: '', searchBoolean: false });
  SetApplicationOnRememberCheck = new BehaviorSubject<number>(14400);
  responseMessage = new BehaviorSubject<any>('');
  renameFolder = new BehaviorSubject<boolean>(false);
  filesAreUploaded = new BehaviorSubject<boolean>(false);
  onDropdownChange = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) { }

  // File Managers Functions Start //

  DataService_EditUserProfile(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/_service/um/editMyProfile', body);
  }

  // //roles list
  DataService_GetRolesList(body): Observable<any> {
    return this.http.get<any>(environment.BaseURL + '/_service/um/getRoles');
  }

  //forgot password request
  DataService_ForgotPassword(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/_preauthsvc/user/forgotPassword', body)
  }
  //Request Verification Link
  DataService_VerificationLink(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/_preauthsvc/user/resendVerifyEmail', body)
  }
  //reset password
  DataService_ResetPassword(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/_preauthsvc/user/resetPassword', body);
  }
  // get folders
  cDataService_GetFolders(): Observable<any> {
    return this.http.get<any>(environment.BaseURL + '/foldersvc/folders');
  }

  //get folder and files
  cDataService_GetFoldersAndFiles(body: any): Observable<any> {
    return this.http.get<any>(environment.BaseURL + '/foldersvc/folderAndFiles?folderId=' + body.id + '&deleted=' + body.deleted + '&orderBy=' + body.orderBy);
  }
  //autocomplete 
  cDataService_SearchAutoComplete(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/foldersvc/autoComplete', body);
  }
  //overall serach
  cDataService_Search(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/foldersvc/search', body);
  }
  DataService_RetrieveSignedUrl(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/foldersvc/cloudstorage/folder/retieveSignedUrl', body);
  }
  DataService_RenameFile(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/foldersvc/cloudstorage/file/rename', body);
  }
  DataService_RenameFolder(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/foldersvc/cloudstorage/folder/rename', body);
  }

  DataService_DeleetFolders(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/foldersvc/cloudstorage/folder/delete', body);
  }
  DataService_PasteFileandFolders(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/foldersvc/cloudstorage/file/move', body);
  }
  DataService_CopyFiles(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/foldersvc/cloudstorage/file/copy', body);
  }
  DataService_DeleteFiles(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/foldersvc/cloudstorage/file/deleteMany', body);
  }
  DataService_NewFolder(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/foldersvc/cloudstorage/folder/create', body);
  }
  DataService_CreateDownloadSignedUrl(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/foldersvc/cloudstorage/folder/createDownloadSignedUrl', body);
  }
  DataService_isBusy(): Observable<any> {
    return this.http.get<any>(environment.BaseURL + '/foldersvc/cloudstorage/folder/isBusy');
  }
  DataService_RestoreFromTrash(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/foldersvc/cloudstorage/file/restoreMany', body);
  }
  DataService_GetUsersGroup(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GroupList', body);
  }
  DataService_GetUsersGroupMembers(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GroupDetail', body);
  }
  DataService_AddNewGroup(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/newGroup', body);
  }
  DataService_DeleteGroup(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/foldersvc/cloudstorage/group/delete', body);
  }
  DataService_AddMember(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/addMember', body);
  }
  DataService_DeleteMember(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/deleteMember', body);
  }
  DataService_EditMember(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/updateMember', body)
  }
  DataService_AssignTypist(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/foldersvc/cloudstorage/file/share', body)
  }
  DataService_CreateReferenceFile(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/CreateReferenceFile', body)
  }
  DataService_GetFileDetails(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetFileDetails', body)
  }
  DataService_UpdateFileStatus(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/foldersvc/cloudstorage/file/updateStatus', body)
  }
  // Methods For Data Source API component 
  DataService_ChangePassword(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/_service/um/changeMyPassword', body)
  }
  DataService_FolderSync(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/foldersvc/cloudstorage/folder/sync', body)
  }
  // Tenant Information
  DataServiceGetTenantInformation(): Observable<any> {
    return this.http.get<any>(environment.BaseURL + '/_service/um/currentUserTenantOrg', {})
  }
  // get folders
  cDataService_GetDocFolders(): Observable<any> {
    return this.http.get<any>(environment.BaseURL + '/docsvc/folders');
  }
  // For shared file Link
  DataService_GetSignedAction(body): Observable<any> {
    return this.http.get<any>(environment.BaseURL + '/_service/public/signedAction?secret=' + body)
  }
  DataService_RetrieveURLforSharedLinkFile(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/_service/public/retieveSignedUrlPublic', body);
  }
  //Get File versions
  DataService_GetFilesVersions(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/page/File', body)
  }
  DataService_GetWBVersion(): Observable<any> {
    return this.http.get<any>(environment.BaseURL + '/_rt/appVersion')
  }
  DataService_ShareFile(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/DICOM_LinkSharedFile/LinkFile', body)
  }
  DataService_EditFileData(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/EditFileData', body);
  }
  DataService_GetClinicalInformation(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/DICOM_ClinicalInformation/GetInfo', body);
  }
  DataService_SaveClinicalInformation(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/DICOM_ClinicalInformation/SaveInfo', body);
  }
  // get tfs folders
  DataService_GetTFSInfo(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/DICOM_FTSInformation/GetInfo', body);
  }
  // DataService_SaveTFSInfo
  DataService_SaveTFSInfo(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/DICOM_FTSInformation/SaveInfo', body);
  }
  // DataService_StatusofInProgressFiles
  DataService_StatusofInProgressFiles(fileIds): Observable<any> {
    let body = {
      fileIds: fileIds
    }
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/DICOM_GetStatusObject/GetStatus', body);
  }
  // DataService_SaveFeedback
  DataService_SaveFeedback(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/DICOM_Feedback/saveFeedback', body);
  }
  // DataService_GetFileFeedback
  DataService_GetFileFeedback(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/DICOM_Feedback/getFileFeedback', body);
  }
  DataService_GetFeedback(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/DICOM_Feedback/getUserFeedback', body);
  }
  DataService_RemoveMetadata(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/DICOM_MediaDeIdentification', body);
  }
  DataService_GetUserReport(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/DICOM_ClinicalInformation/GetUserReport', body);
  }
  DataService_GetGroupRole(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/DICOM_GetUserGroupRole', body);
  }
}

